<template>

    <div class="popup active">
        <div class="notice_popup human int">
            <div class="close">
                <img src="/images/close.png" alt="닫기" @click="$emit('close', false)"/>
            </div>
            
			<div v-if="gubun == 'insert'" class="tit">관심프로젝트 등록</div>
			<div v-else class="tit">관심프로젝트 삭제</div>

            <div class="prj_name">
                <p>프로젝트명</p> <span class="name" title="">{{projectNm}}</span>
            </div>
            <div v-if="gubun == 'insert' && recrCloseYn != 'Y'" class="member">위 프로젝트를 '관심프로젝트'로 등록하시겠습니까?</div>
			<div v-else-if="gubun == 'insert' && recrCloseYn == 'Y'" class="member line">모집마감된 프로젝트 입니다.<br/>'관심프로젝트'로 등록 하시겠습니까?</div>
			<div v-else class="member">위 프로젝트를 '관심프로젝트'에서 삭제하시겠습니까?</div>
            <div class="select-btn">
                <div class="btn" @click="$emit('close', false)">취소</div>
                <div class="btn" @click="save()">예</div>
            </div>
        </div>
    </div>

</template>

<script>

export default {

	components : {		
	},

	props: ['param'],

	data() {
		return {
			projectSeq : this.param.projectSeq,			
			projectNm : this.param.projectNm,
			gubun : this.param.gubun,				// insert, delete	
			recrCloseYn : this.param.recrCloseYn	// 모집마감 여부	
		};
	},
	beforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');		
	},
	mounted() {
		// console.log('mounted');
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {
		save() {
			var input = {
				projectSeq : this.projectSeq
			}

			var apiUrl = '';
			if(this.gubun == 'insert') {
				apiUrl = '/api/prj/tec/insertInterestProject';
			} else if(this.gubun == 'delete') {
				apiUrl = '/api/prj/tec/deleteInterestProject';
			}

			this.$.httpPost(apiUrl, input)
				.then(() => {
					this.$emit('close', true);
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});						

		}
	},
};
</script>
